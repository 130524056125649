import { Injectable, inject } from "@angular/core";
import {
    BehaviorSubject,
    EMPTY,
    Observable,
    catchError,
    filter,
    map,
    of,
    switchMap,
    tap,
    throwError,
    timer
} from "rxjs";
import { User, UserRole } from "../models/user";
import { Router } from "@angular/router";

import { toSignal } from "@angular/core/rxjs-interop";
import { DOCUMENT } from "@angular/common";
import { environment } from "../../environments/environment";
import {
    UserControllerService,
    UserProfileEnum,
    UserRoleEnum
} from "../api/v1";
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders
} from "@angular/common/http";
import { AuthTokenResponse } from "../models/authTokenResponse";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    router = inject(Router);
    http = inject(HttpClient);
    document = inject(DOCUMENT);
    userService = inject(UserControllerService);

    _user$ = new BehaviorSubject<User | undefined | null>(undefined);

    // ----user-----
    user$ = this._user$.pipe(filter((user) => user !== undefined));
    roles$: Observable<UserRole[]> = this._user$.pipe(
        map((user) => user?.roles ?? [])
    );

    // ----isDealer-----
    isDealer$ = this.roles$.pipe(
        map(
            (roles) =>
                roles.includes(UserRole.DEALER_OPERATOR) ||
                roles.includes(UserRole.DEALER_READER)
        )
    );
    isDealerOperator$ = this.roles$.pipe(
        map((roles) => roles.includes(UserRole.DEALER_OPERATOR))
    );
    isDealerReader$ = this.roles$.pipe(
        map((roles) => roles.includes(UserRole.DEALER_READER))
    );
    isDealer = toSignal(this.isDealer$, {});
    isDealerOperator = toSignal(this.isDealerOperator$, {});
    isDealerReader = toSignal(this.isDealerReader$, {});

    // ADMIN

    // ----isMarketingManager-----
    isMarketingManager$ = this.roles$.pipe(
        map((roles) => roles.includes(UserRole.MARKENTING_MANAGER))
    );
    isMarketingManager = toSignal(this.isMarketingManager$, {});

    // ----isMarketingManagerIT-----
    isSuperMarketManager$ = this.roles$.pipe(
        map((roles) => roles.includes(UserRole.SUPER_MARKET_MANAGER))
    );
    isSuperMarketManager = toSignal(this.isSuperMarketManager$, {});

    // ----isHq-----
    isHq$ = this.roles$.pipe(map((roles) => roles.includes(UserRole.HQ)));
    isHq = toSignal(this.isHq$, {});

    // ----isAdmin-----
    isAdmin$ = this.roles$.pipe(map((roles) => roles.includes(UserRole.ADMIN)));
    isAdmin = toSignal(this.isAdmin$, {});

    // ----isLoggedIn-----
    isLoggedIn$ = this._user$.pipe(map((user) => user !== undefined));

    loginCompleted$ = new BehaviorSubject<boolean>(false);

    currency$ = this._user$.pipe(map((u) => u?.infos.currencyCode));
    currency = toSignal(this.currency$, { initialValue: undefined });

    constructor() {
        timer(0).subscribe(() => {
            let query = new URLSearchParams(this.document.location.search);
            let code = query.get("code") ?? undefined;
            let error = query.get("error");
            let error_description = query.get("error_description");

            this.logError(error, error_description);
            if (!error) this.checkAuth(code).subscribe();
        });
    }

    checkAuth(code?: string) {
        return ((code ? this.useCode(code) : of(true)) as Observable<any>).pipe(
            catchError(() => of(true)),
            switchMap(() => this.getUser()),
            catchError((err) => of(err))
        );
    }

    useCode(code: string) {
        let headers = new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded"
        });
        let body = new URLSearchParams();
        body.set("code", code);
        body.set("client_id", environment.auth.client_id);
        body.set(
            "redirect_uri",
            window.location.origin + environment.auth.redirect_uri
        );
        body.set("grant_type", "authorization_code");
        return this.http
            .post<AuthTokenResponse>(environment.auth.tokenUrl, body, {
                headers
            })
            .pipe(
                tap((res) => this.registerToken(res)),
                catchError(({ error }) => {
                    if (error)
                        this.logError(error.error, error.error_description);
                    return throwError(() => error);
                })
            );
    }

    useRefreshToken(token: string) {
        let headers = new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded"
        });
        let body = new URLSearchParams();
        body.set("refresh_token", token);
        body.set("client_id", environment.auth.client_id);
        body.set("grant_type", "refresh_token");
        return this.http
            .post<AuthTokenResponse>(environment.auth.tokenUrl, body, {
                headers
            })
            .pipe(
                tap((res) => this.registerToken(res)),
                catchError(({ error }) => {
                    [
                        "access_token",
                        "token_type",
                        "expires_in",
                        "refresh_token",
                        "refresh_token_expires_in",
                        "id_token"
                    ].forEach((val) => {
                        localStorage.removeItem(val);
                    });
                    if (error)
                        this.logError(error.error, error.error_description);
                    return throwError(() => error);
                })
            );
    }

    registerToken(data: AuthTokenResponse) {
        console.log("received tokens", data);
        Object.entries(data).forEach(([key, value]) => {
            localStorage.setItem(key, value.toString());
        });
    }

    getUser(): Observable<User> {
        return this.userService.getUserInfo().pipe(
            map((user: any) => {
                let roles = [];
                switch (user.role) {
                    case UserRoleEnum.Admin:
                        roles.push(UserRole.ADMIN);
                        break;
                    case UserRoleEnum.Hq:
                        roles.push(UserRole.HQ);
                        break;
                    case UserRoleEnum.MarketManager:
                        roles.push(UserRole.MARKENTING_MANAGER);
                        break;
                    case UserRoleEnum.SuperMarketManager:
                        roles.push(UserRole.SUPER_MARKET_MANAGER);
                        break;
                    case UserRoleEnum.Dealer:
                        if (user.profile === UserProfileEnum.Viewer) {
                            roles.push(UserRole.DEALER_READER);
                        } else {
                            roles.push(UserRole.DEALER_OPERATOR);
                        }
                        break;
                    default:
                        break;
                }
                return {
                    roles,
                    infos: user
                };
            }),
            tap((user) => this._user$.next(user)),
            tap(() => this.loginCompleted$.next(true))
        );
    }

    authErrorOnApi(error?: HttpErrorResponse) {
        if (
            (!error || error.status === 401) &&
            !localStorage.getItem("access_token")
        ) {
            let token = localStorage.getItem("refresh_token");
            if (token) {
                return this.useRefreshToken(token).pipe(
                    switchMap(() => throwError(() => error))
                );
            }

            let url = new URL(environment.auth.loginUrl);
            url.searchParams.append("response_type", "code");
            url.searchParams.append("resource", environment.auth.appName);
            url.searchParams.append("client_id", environment.auth.client_id);
            url.searchParams.append(
                "redirect_uri",
                window.location.origin + environment.auth.redirect_uri
            );
            this.document.location.assign(url.href);
            return EMPTY;
        }
        this.loginCompleted$.next(true);
        return throwError(() => error);
    }

    logError(error?: string | null, error_description?: string | null) {
        if (error && error_description) console.error(error_description);
    }
}
